/* .owl-carousel .owl-nav button.owl-prev, */
/* .owl-carousel .owl-nav button.owl-next, */
/* .owl-carousel button.owl-dot { */
/* margin-left: 2px;
  margin-right: 2px;
  line-height: 0;
  font-size: 100px;
  font-weight: 100;
  color: #000 !important; */

/* }  */
.owl-theme .owl-dots {
  display: flex;
  flex: 1;
  justify-content: center;
}

.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span,
.owl-carousel button.owl-dot span {
  font-size: 90px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  margin-top: 0px !important;
}

/* .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:focus span{
  background: #000;
} */

/* 
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
  background: #000;
} */

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 1px 1px;
}

.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -32px;
  margin-top: 58px;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -32px;
  margin-top: 60px;
}

.owl-prev.disabled {
  opacity: 0.04;
  color: rgb(238, 238, 238);
}

.owl-next.disabled {
  opacity: 0.2;
  color: rgb(238, 238, 238);
}

.owl-carousel.owl-drag .owl-item a {
  text-decoration-line: none;
}

.owl-carousel.owl-drag .owl-item .productAbout span:hover {
  text-decoration-line: underline;
}

.MuiGrid-item a {
  text-decoration-line: none;
  color: white;
}

.MuiButton-root {
  min-width: 0;
}

.MuiTabs-root {
  height: 36px;
  width: 653.33px;
  min-height: 0 !important;
  display: block !important;
  background-color: white;
}

/* .MuiTab-wrapper{
  display: block;
  border-color: #000;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
} */

.MuiTabs-fixed {
  height: 36px;
}

.MuiTabs-flexContainer {
  height: 36px;
}

.MuiTab-root {
  min-height: 0 !important;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 80px !important;
  }
}

.MuiAppBar-colorPrimary {
  color: black !important;
  background-color: white !important;
}

.MuiTab-fullWidth {
  flex-basis: auto !important;
}

.mycarousel.owl-carousel.owl-loaded {
  display: flex;
}

.mycarousel.owl-carousel .mycarousel.owl-stage {
  transform: none;
  width: 3000px !important;
}

.mycarousel.owl-item.active {
  flex-shrink: 0 !important;
  display: flex !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  width: 211px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.HomePageCorouselButtons {
  font-size: 12px;
  padding-left: 0.875em;
  padding-right: 0.875em;
  min-height: 32px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  box-sizing: border-box;
  -webkit-appearance: none;
  color: rgb(0, 0, 0);
  display: inline-flex;
  border-style: solid;
  border-width: 1px;
  text-decoration: none;
  outline: 0px;
  border-radius: 4px;
  border-color: currentcolor;
  transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
}

