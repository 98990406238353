.dropbtn {
    display: flex;
    flex: 1 ; 
    flex-direction: row;
    height: 70px ;
    padding: 0px 15px ;
    align-items: center ;
}

/* .dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
} */


  
.dropdown {
  position: relative;
  display: block;
 

}

 /* .dropdown-content {
  display: flex;
  background-color: #fff;
  color: #000;
   min-width: 160px;
   
  overflow: auto; 
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); 
  z-index: 1; 
  box-shadow: 0 1px 8px rgba(0,0,0,.15);
  
  flex-direction: column;
    right: 75px;
  
    z-index: 1030;
    width: 343px;
    left: auto;
    right: 20px;
    
}  */

.dropdown-content {
 
  display: flex;
  flex: 1;
  background-color: #fff;
  color: #000;
  box-shadow: 0 1px 8px rgba(0,0,0,.15);
  flex-direction: column;
  top: 165px;
    z-index: 1030;
    width: 100%;
    left: auto;
    right: 0px;
    position: absolute;
}
.community-dropdown{
  display: flex;
  position: absolute;
  background-color: #fff;
  color: #000;
    left: 0;
    top: 80%;
    z-index: 1030;
    width: 343px;
    left: 36%;
    right: auto;
    margin-left: -171.5px;
    box-shadow: 0 1px 8px rgba(0,0,0,.15);
}



/* .dropdown a:hover {background-color: #ddd;} */

.show {display: block;}