* {
  border-width: 0;
  border-style: solid;
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: inherit;
  font-family: 'Roboto';
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-decoration: none;
  align-items: normal;
  min-width: 0;
}

.MuiDrawer-paper {
  width: 70%;
  max-width: 315px;
}

p {
  font-size: 14px;
}

b,
strong {
  font-weight: 700 !important;
}

.small {
  font-size: 12px !important;
}

.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  fill-rule: inherit;
}

.App-header {
  background-color: #000;
  min-height: 34px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.App-navbar {
  background-color: #fff;
  min-height: 112px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: "center";
}

.App-name {
  min-height: 72px;
  display: flex;
  justify-content: center;
}

.App-mainNavbar {
  background-color: #fff;
  min-height: 40px;
  padding-left: 68px;
  padding-right: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  /* font-family: "helvetica neue", helvetica, arial, sans-serif; */
  font-family: 'Roboto';
  line-height: 1.5;
  height: 100%;
  color: #000;
  background-color: #fff;
}

.container-sm {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.carousel-item-hover {
  display: none;
}

.carouselBodyPosition:hover .carousel-item-hover {
  display: flex;
}

.carouselBodyPosition:hover .productAbout>span {
  text-decoration-line: underline;
}

.product-color-div {
  position: relative;
  border-width: 3px;
  border-style: solid;
  border-radius: 7px;
  cursor: default;
  border-color: transparent;
  padding: 1px;
  cursor: pointer;
}

.product-color:hover .product-color-div {
  border-color: pink;
}

.product-color:focus .product-color-div {
  border-color: black;
}

.DividerLine {
  display: block;
  color: #eee;
  margin-bottom: 24px !important;
  margin-top: 24px !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}

.basketDividerLine {
  display: block;
  color: rgb(0, 0, 0);
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
}

.carouselBodyPosition {
  display: block;
  position: relative;
  width: 147px;
  padding-left: 10px;
  padding-right: 10px;
}

.HomePageCorouselButtons {
  font-size: 12px;
  padding-left: 0.875em;
  padding-right: 0.875em;
  min-height: 32px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  box-sizing: border-box;
  -webkit-appearance: none;
  color: rgb(0, 0, 0);
  display: inline-flex;
  border-style: solid;
  border-width: 1px;
  text-decoration: none;
  outline: 0px;
  border-radius: 4px;
  border-color: currentcolor;
  transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
}

.menuBtn {
  text-decoration-line: none;
}

.itemFlexBox {
  display: flex;
  text-align: center;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row wrap;
}

.itempagefooter {
  display: block;
  cursor: pointer;
  color: rgb(117, 117, 117);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 29.3333%;
  line-height: 1.25;
  outline: 0px;
  font-size: 14px;
  text-decoration-line: none;
}

a.itempagefooter:hover {
  text-decoration-line: underline;
}

.textInput {
  display: flex;
  flex: 1;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  margin: 1px;
  color: #000;
  background-color: transparent;
  border-radius: 4px;
  border-width: 0;
  box-shadow: none;
  padding-left: 36px;

  /* border-radius: 50px;
background-color: #f6f6f8;
border: 1px solid #f6f6f8;
padding-left: 30px;
width: 100%;
height: 38px; */
}

.textInputIcon {
  display: inline-block;
  width: 20;
  height: 20;
  vertical-align: text-bottom;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 16px;
  color: #c5c5c7;
  position: absolute;
  top: 8px;
  left: 23px;
  pointer-events: none;
}

.category .row {
  flex-wrap: nowrap;
}

.searchType {
  display: flex;
  align-items: center;
  position: relative;
  -webkit-box-align: center;
}

@media (min-width: 1280px) {
  .searchType {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media (min-width: 800px) {
  .searchType {
    margin-right: -12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

@media (min-width: 992px) {
  .searchType .col-lg-2 {
    max-width: 11.666667%;
  }

  .searchType .col-lg-5 {
    max-width: 35.666667%;
  }
}

@media (max-width: 1200px) {
  .heart-Logo {
    display: flex !important;
    flex: 4 !important;
    justify-content: center !important;
  }

  .basket-Logo {
    display: flex !important;
    flex: 4 !important;
    justify-content: center !important;
  }

  .store-Flex {
    flex: 4 !important;
  }
}

@media (min-width: 1200px) {
  .store-Logo {
    padding-right: 12px;
  }
}

.css-b62crn:first-of-type {
  margin-top: -1px;
  border-top: 8px solid #f6f6f8;
}

.css-b62crn {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-weight: 700;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
}

.filter-box {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 4px;
  border: 1px solid #eee;
  display: block;
  border-width: 1px;
  height: 132px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 24px;
}

.filter-label {
  font-size: 12px !important;
}

.filter-checkbox {
  color: black !important;
}

/* #region */
.css-jp4jy6 {
  color: #000;
  font-size: 12px;
  width: calc(5em + 0.668em);
  height: 1em;
  position: relative;
  overflow: hidden;
  line-height: 0;
  text-align: left;
}

.css-1w5zg6j {
  opacity: 0.2;
}

.css-1be0bml {
  display: inline-block;
  background-image: url(/assets/star.svg);
  background-size: cover;
  width: 1em;
  height: 1em;
}

.css-1v1px9n {
  display: inline-block;
  background-image: url(/assets/star.svg);
  background-size: cover;
  width: 1em;
  height: 1em;
  margin-left: 0.167em;
}

.css-j7llew {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  opacity: 1;
}

/* #endregion */

.sticky {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  align-self: flex-start;
  /* <-- this is the fix */
}

.mycarousel .slick-slider img {
  width: 18px;
  height: 36px;
}

.mainPosterLink {
  display: flex;
  flex: 1;
  margin-top: -41.66% !important;
}

.quickLookDialog {
  width: 100% !important;
  padding: 16px 16px 32px;
  padding-left: 24px;
  padding-right: 24px;
}

.navCategoryBox {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 0px;
  /* top: 41px; */
  width: 100%;
}

.sub_category_link {
  color: black;
  font-size: 14px;
  height: 33px;
}

.sub_category_link:hover {
  color: black;
}

.category_link {
  font-size: 14px;
}

.category_link:hover {
  text-decoration: none;
}

.checkout_navbar {
  box-shadow: rgba(0, 0, 0, 0.125) 0px 1px 4px 0px;
}

.cartCount {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 99999px;
  font-weight: 700;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background-color: rgb(207, 17, 44);
  width: 20px;
  height: 20px;
  position: relative;
  top: -35px;
  right: -7px;
}

.cartCount span {
  font-size: 10px;
  color: white;
}

.nav-icon {
  cursor: pointer;
}

.cart-item-nav-container,
.cart-item-nav-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.063);
  border-top-color: rgba(0, 0, 0, 0.063);
  border-right-color: rgba(0, 0, 0, 0.063);
  border-left-color: rgba(0, 0, 0, 0.063);
  padding: 12px 16px;
  cursor: pointer;
}

.cart-item-nav-container:hover span {
  text-decoration-line: underline;
}

.addedToBasketDialogContainer {
  align-items: flex-start !important;
}

.addedToBasketDialogPaper {
  width: 100%;
}

.addedToBasketDialogTitle {
  text-align: center;
}

.button-custom {
  font-size: 14px;
  padding: 0.25em 1.125em;
  min-height: 44px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1;
  background-color: transparent;
  border-style: solid;
  border-width: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  outline: 0px;
  box-sizing: border-box;
  border-radius: 99999px;
  appearance: none;
  color: rgb(0, 0, 0);
  border-color: currentcolor;
  transition: color 0.2s ease 0s, border-color 0.2s ease 0s;
  display: flex;
  width: 100%;
  min-width: 5.5em;
}

.button-checkout {
  color: rgb(255, 255, 255);
  transition: background-color 0.2s ease 0s;
  background-color: rgb(207, 17, 44);
  border-color: rgb(207, 17, 44);
  margin-bottom: 15px;
}

.innerLink:hover p {
  text-decoration: underline;
}

.MuiFormControl-root {
  margin-bottom: 15px !important;
}

.MuiFilledInput-root {
  background-color: transparent !important;
  height: 44px;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
}

.MuiFormLabel-root.Mui-focused {
  color: rgb(117, 117, 117) !important;
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after {
  border-bottom: none !important;
}

.MuiFormControl-root label {
  color: rgb(117, 117, 117);
  font-size: 14px;
  margin-top: -3px;
}

.MuiFormControl-root input {
  font-size: 14px !important;
}

.underline-hover:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

.divider {
  border-bottom: 2px solid rgb(0, 0, 0);
  border-top-color: rgb(0, 0, 0);
  border-right-color: rgb(0, 0, 0);
  border-left-color: rgb(0, 0, 0);
  margin-top: 12px;
  margin-bottom: 12px;
}

.checkout-btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: rgb(102, 102, 102);
  border-color: rgb(238, 238, 238);
  background-color: rgb(238, 238, 238);
}

.checkout-btn {
  font-size: 14px;
  padding: 0.25em 1.125em;
  min-height: 44px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  outline: 0px;
  box-sizing: border-box;
  border-radius: 99999px;
  appearance: none;
  color: rgb(255, 255, 255);
  transition: background-color 0.2s ease 0s;
  background-color: rgb(207, 17, 44);
  display: flex;
  width: 100%;
  min-width: 15em;
}

.item-in-order-header {
  outline: 0px;
  display: block;
  width: 100%;
  padding: 16px;
  line-height: 1;
  background-color: rgb(246, 246, 248);
  font-weight: 700;
}

.out-of-stock-p {
  display: flex;
  justify-content: flex-end;
  color: red;
  margin-top: -30px;
}

.header-title {
  font-size: 32px;
  font-family: georgia, times, serif;
  margin: 0px;
}

.table th {
  border-top: none !important;
  font-weight: bold;
}

th,
td,
th b,
td b {
  font-size: 14px;
}

.table td {
  vertical-align: middle !important;
}

.pagination {
  float: right;
}

.full_description ul {
  margin-left: 45px;
}

.full_description ul li {
  font-size: 14px;
}

#more_beauty_for_you_banner {
  img {
    max-width: 100%;

    @media (min-width: 512px) {
      width: 221px;
      height: 404px;
      object-fit: contain;
      object-position: center;
    }
  }
}